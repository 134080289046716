import { combineReducers } from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import supportReducer from "./supportReducer";
import rebrandReducer from "./rebrandReducer";
import socialReducer from "./socialReducer";
import storyReducer from "./storyReducer";
import extraReducer from "./extraReducer";
import publishReducer from "./publishReducer";
import { WhiteLabelData } from "./WhiteLabelData";

const appReducer = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    support: supportReducer,
    rebrand: rebrandReducer,
    social: socialReducer,
    extra: extraReducer,
    story: storyReducer,
    publish: publishReducer,
    wl: WhiteLabelData,
})



const rootReducers = (state, action) => {

    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = {
            ...state,
            alert: undefined,
            auth: undefined,
            support: undefined,
            social: undefined,
            extra: undefined,
            story: undefined,
            publish: undefined,
            rebrand: +state.auth?.user?.isWL === 1 ? state ? state.rebrand : undefined : undefined,

            wl: undefined,
        };
    }
    return appReducer(state, action)
}

export default rootReducers;