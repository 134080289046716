import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import Webinar from "./Webinar";
import { useEffect } from "react";
import { loadUser } from "../../actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { appName } from "../../global/global";


const Dashboard = () => {
    const brandName = useSelector(state => state.rebrand.data);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(loadUser())
    }, [])
    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Dashboard </title>
            </Helmet>            <Navbar />
            <section className="siteWrap">
                <DashboardHead />
                <DashboardContent />
                <DashboardAbout />
            </section>
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;