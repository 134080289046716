import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link, useHistory } from "react-router-dom";
import loginBg from "../../images/BG.png";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { resetPassword } from "../../actions/authAction";
import Alert from "../Alert";
import { Helmet } from "react-helmet";

import logo from '../../images/LOGO.png';
import TitleBar from "../TitleBar";
import { appName } from "../../global/global";

const ResetPassword = ({ location }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const rebrandData = useSelector(state => state.rebrand.data);
    const brandName = useSelector(state => state.rebrand.data);

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: "",
        confirm_password: "",
        password_token: queryString.parse(location.search).token,
    });
    const [error, setError] = useState("");

    const onInputChange = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
        setError(""); // Reset error when user types
    };

    const validatePassword = () => {
        const { confirm_password } = password;
        if (password.password.length < 8) return "Password must be at least 8 characters long.";
        if (!/[A-Z]/.test(password.password)) return "Password must contain at least one uppercase letter.";
        if (!/[a-z]/.test(password.password)) return "Password must contain at least one lowercase letter.";
        if (!/[0-9]/.test(password.password)) return "Password must contain at least one number.";
        if (password.password !== confirm_password) return "Passwords do not match.";
        return "";
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        const validationError = validatePassword();
        if (validationError) {
            setError(validationError);
            setLoader(false);
            return;
        }

        dispatch(resetPassword(password, setLoader, history));
    };

    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Reset Password </title>
            </Helmet>            <Alert />

            <header className="loginHeader d-none">
                <div className="container">
                    <div className="loginLogo">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <img src={logo} alt="logo" />
                                <h2><span>Welcome To </span>{rebrandData?.name ? rebrandData?.name : appName}!</h2>
                                <p>Instantly create short brandable videos using fully-customizable <br /> templates optimized for social media, ads, and more.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Reset</span> Password</h2>
                                    <form method="post" onSubmit={onFormSubmit}>
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input type="password" name="password" className="form-control"
                                                value={password.password}
                                                required onChange={onInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" name="confirm_password" className="form-control"
                                                value={password.confirm_password}
                                                required onChange={onInputChange}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Login? <Link to="/login">Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginFooter />
        </>
    );
}

export default ResetPassword;
