import React, { useState } from "react";
import Header from './Header'
import EditorLeft from "./left/EditorLeft";
import EditorRight from "./right/EditorRight";
import EditorMid from "./Middel/EditorMid";
import Alert from "../Alert";
import TitleBar from "../TitleBar"
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStory, onUnmountStory } from "../../actions/storyAction";
import { ActionCreators } from "redux-undo";
import { appName } from "../../global/global";
import { Helmet } from "react-helmet";


const CreateVideo = ({ location }) => {
    const id = queryString.parse(location.search).id
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const storyData = useSelector(state => state.story)
    const [loader, setLoader] = useState(true)
    const brandName = useSelector(state => state.rebrand.data);

    const fetchStoryData = () => {
        dispatch(fetchStory(id, auth.token, setLoader))
    }


    useEffect(() => {
        if (id) {
            fetchStoryData()
        }
    }, [id])



    return (
        <>
            <Alert />
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Editor </title>
            </Helmet>
            {loader ?
                <div className="loader-sec">
                    <div className="loader">
                    </div>
                </div>
                :
                <div className="editorWrap" style={{ padding: "0px" }}>
                    <Header
                        name={storyData.name}
                    />
                    <div className="Mainarea d-flex flex-column flex-lg-row">
                        <div className="col-12 col-lg-4 p-0">
                            <EditorLeft storyData={storyData} />
                        </div>
                        <div className="col-12 col-lg-8 p-0">
                            <div className="d-flex flex-column flex-md-row gap-3 justify-content-between px-3">
                                <EditorMid storyData={storyData} />
                                <EditorRight storyData={storyData} />
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export default CreateVideo; 