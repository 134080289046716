import './App.css';
import './WhiteLabel.css';

import './responsive.css'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { PrivateRoute } from "./components/PrivateRoute";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Profile from "./components/user/Profile";
import Privacy from "./components/user/Privacy";
import AccountManagement from "./components/user/AccountManagement";
import Upgrades from "./components/user/Upgrades";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import HelpSupport from "./components/support/HelpSupport";
import Training from "./components/support/Training";
import TrainingArticles from "./components/support/TrainingArticles";
import MasterLogin from "./components/MasterLogin";
import Integration from "./components/integration/Integration";
import Reseller from "./components/user/Reseller";
// import Editor from './components/editor/Editor';
import { removeAlert } from './actions/alert';
import CreateVideo from './components/CreateVideos/CreateVideo';
import Publish from './components/publish/Publish';
import Stories from './components/Stories/Stories';
import 'animate.css'
import WhiteLabelTabs from './components/WhiteLabel/WhiteLabelTabs';
import { loadUser, onFetchWhiteLabelData } from './actions/authAction';


function App() {
    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const rebrand = useSelector(state => state.rebrand.data)


    useEffect(() => {
        dispatch(loadUser())
    }, [])
    const fetchWhiteLabel = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let obj = { domain: window.location.host }
        dispatch(onFetchWhiteLabelData(obj, loader, setLoader))
    }
    useEffect(() => {
        if (rebrand && rebrand.color?.first && rebrand.color?.second) {
            document.documentElement.style.setProperty('--primary', rebrand.color.first);
            document.documentElement.style.setProperty('--secondary', rebrand.color.second);
        }
    }, [rebrand])

    useEffect(() => {

        fetchWhiteLabel();
    }, [])
    useEffect(() => {
        if (alert !== undefined) {
            if (alert.message !== "") {
                setTimeout(() => {
                    dispatch(removeAlert())
                }, 4000);
            }
        }
    }, [alert, dispatch])


    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={ForgetPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/master-login" component={MasterLogin} />
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/privacy" component={Privacy} />
                    <PrivateRoute exact path="/account-management" component={AccountManagement} />
                    <PrivateRoute exact path="/reseller" component={Reseller} />
                    <PrivateRoute exact path="/upgrades" component={Upgrades} />
                    <PrivateRoute exact path="/help-and-support" component={HelpSupport} />
                    <PrivateRoute exact path="/training" component={Training} />
                    <PrivateRoute exact path="/support-article" component={TrainingArticles} />
                    <PrivateRoute exact path="/stories" component={Stories} />
                    <PrivateRoute exact path="/integration" component={Integration} />
                    <PrivateRoute exact path="/create-story" component={CreateVideo} />
                    <PrivateRoute exact path="/publish" component={Publish} />
                    <PrivateRoute exact path="/white-label" component={WhiteLabelTabs} />

                    <Route path="*" component={Login} />


                </Switch>
            </Router>
        </div>
    );
}

export default App;
