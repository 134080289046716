import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import UpgradesMenu from "./UpgradesMenu";
import UpgradesContent from "./UpgradesContent";
import TitleBar from "../TitleBar";
import { useSelector } from "react-redux";
import { appName } from "../../global/global";
import { Helmet } from "react-helmet";

const Upgrades = () => {
    const [show, setShow] = useState(false)
    const brandName = useSelector(state => state.rebrand.data);


    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Upgrades </title>
            </Helmet>            <Navbar />

            <section className="siteWrap">
                <div className="tabCont white">
                    <div className="row">
                        <div className="col-lg-4 tabLeft">
                            <div className="tabCont-left">
                                <UpgradesMenu setShow={setShow} />
                            </div>
                        </div>


                        <UpgradesContent show={show} />

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Upgrades;