import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { masterLoginUser } from "../actions/authAction";
import Alert from "./Alert";
import logo from '../images/LOGO.png';
import TitleBar from "./TitleBar";
import LoginFooter from "./auth/LoginFooter";
import { appName } from "../global/global";
import { Helmet } from "react-helmet";


const MasterLogin = () => {

    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const rebrand = useSelector(state => state.rebrand.data)
    const dispatch = useDispatch();
    const brandName = useSelector(state => state.rebrand.data);

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: '',
        password: '',
        useremail: ""
    })

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(masterLoginUser(user, setLoader, history));
    }

    return (
        <>
            <Helmet >
                <title>{brandName !== false ? brandName.name : appName} | Master Login </title>
            </Helmet>            <Alert />

            <header className="loginHeader d-none">
                <div className="container">
                    <div className="loginLogo">
                        <img src={logo} />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <img src={logo} />
                                <h2>Welcome <span>{appName}</span></h2>
                                <p>Instantly create short brandable videos using fully-customizable <br /> templates optimized for social media, ads, and more.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2 className="mb-3"><span>Login</span> to Your Account Now</h2>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>User Email Address</label>
                                            <input type="email" name="useremail" className="form-control"
                                                placeholder="User Email" required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group my-1">
                                            <label>Admin Email Address</label>
                                            <input type="email" name="email" className="form-control"
                                                placeholder="Admin Email" required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group my-1">
                                            <label>Admin Password</label>
                                            <input type="password" name="password" className="form-control"
                                                placeholder="************" required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Login to StoryReel
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Forgot your account details? <Link to="/forgot-password" className="text-center "> Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <LoginFooter />

        </>
    )
}

export default MasterLogin;