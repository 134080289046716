import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { onCreateMembership } from '../../actions/WhiteLabelActions'
import { AiOutlineClose } from 'react-icons/ai'

const CreateMemModal = ({ show, handleClose, fetchMemberships, curElem }) => {
    const dispatch = useDispatch()
    const stripe = useSelector(state => state.wl?.stripe)
    const [paymentAcc, setPaymentAcc] = useState([])
    const [state, setState] = useState({
        name: "",
        payment: {
            methodType: "",
            accId: 0,
            paytime: "monthly",
            price: 1
        }

    })
    const [errors, setErrors] = useState({
        price: ''
    })
    const [loader, setLoader] = useState({
        create: false
    })


    const handleSubmit = (e) => {
        e.preventDefault()
        if (!/^-?\d+(\.\d{1,2})?$/.test(state.payment.price)) {
            setErrors({ price: 'Please enter a valid price (only numbers are allowed)' })
            return
        }
        setLoader({
            ...loader,
            create: true
        })
        let obj = { ...state }
        let url = "create-membership"
        if (obj.id) {
            url = "update-membership"
        }
        dispatch(onCreateMembership(url, obj, fetchMemberships, handleClose, loader, setLoader))

    }

    const handleChange = (e) => {
        let { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    // const handlePayment = (e) => {
    //     let { name, value } = e.target

    //     setState((draft) => {
    //         draft.payment = {
    //             ...draft?.payment,
    //             [name]: value
    //         }
    //     })
    // }
    const handlePayment = (e) => {
        let { name, value } = e.target
        if (name === "price") {
            value = parseInt(value);
        }
        setState({
            ...state,
            payment: {
                ...state.payment,
                [name]: value
            }
        })
    }

    useEffect(() => {
        if (state.payment.methodType === "stripe") {
            setPaymentAcc(stripe)
        } else {
            setPaymentAcc([])
        }
    }, [state.payment.methodType, stripe])

    useEffect(() => {
        if (curElem) {
            setState(curElem)
        }
    }, [curElem])


    return (
        <Modal className="white_label_modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="white_Label_modal_wrapper">
                    <div className="white_label_profile">
                        <div className="white-titleBar-left">
                            <h2 className='text-capitalize'>{state.id ? 'Update' : 'Create New'} Membership</h2>
                        </div>
                        <span className="white_modalClose" onClick={handleClose}><AiOutlineClose /></span>

                    </div>
                    <form onSubmit={handleSubmit} className='mt-3'>
                        <div className="white-input-wrap mt-0">
                            <label htmlFor="">Name</label>
                            <div className="white-outer ">
                                <input
                                    type="text"
                                    className="white_inp"
                                    placeholder="Enter Your Name"
                                    name='name'
                                    value={state.name}
                                    onChange={handleChange}
                                    readOnly={state.id ? true : false}
                                    required
                                />
                            </div>
                        </div>

                        <div className="white-input-wrap">
                            <label htmlFor="">Payment</label>
                            <div className='row p-2'>
                                <div className='col-12 col-sm-6'>
                                    <label htmlFor="">Payment method</label>
                                    <div className="white-outer ">
                                        <select
                                            className="white_inp"
                                            name="methodType"
                                            value={state.payment.methodType}
                                            onChange={handlePayment}
                                            required
                                        >
                                            <option value="">Select Method</option>
                                            {/* <option value={"paypal"}>Paypal</option> */}
                                            <option value={"stripe"}>Stripe</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                    <label htmlFor="">Payment account list</label>
                                    <div className="white-outer ">
                                        <select
                                            className="white_inp"
                                            name="accId"
                                            value={state.payment.accId}
                                            onChange={handlePayment}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {paymentAcc.length > 0 ?
                                                paymentAcc?.map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={curElem.id}>{curElem.name}</option>
                                                    )
                                                })
                                                : ""}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 mt-3 mt-sm-4'>
                                    <label htmlFor="">Paytime</label>
                                    <div className="white-outer ">
                                        <select
                                            className="white_inp"
                                            name="paytime"
                                            value={state.payment.paytime}
                                            onChange={handlePayment}
                                            required
                                        >
                                            <option value={"monthly"}>Monthly</option>
                                            <option value={"yearly"}>Yearly</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 mt-3 mt-sm-4">
                                    <label htmlFor="">Price ($)</label>
                                    <div className="white-outer ">
                                        <input
                                            type='number'
                                            name="price"
                                            className="white_inp"
                                            value={state.payment.price}
                                            onChange={handlePayment}
                                            required
                                        />
                                    </div>
                                    {errors.price && <div className="alert alert-danger">{errors.price}</div>}

                                </div>
                            </div>
                            {state.payment.methodType && paymentAcc.length === 0 ?
                                <div className="alert alert-info" role="alert">
                                    You do not have {state.payment.methodType} integration. please connect it from payment integration.
                                </div> : null}
                        </div>

                        <div className="white-input-wrap text-center text-sm-end">
                            <button className="white-theme-btn bdr me-1" type='button' onClick={handleClose}><span>Cancel</span></button>
                            <button className="white-theme-btn" type='submit'><span>{loader.create ? <>Creating <FaSpinner className="spin" /></> : "Create"}</span></button>
                        </div>
                    </form>
                </div>

            </Modal.Body>
        </Modal >
    )
}

export default CreateMemModal